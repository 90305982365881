import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  createFormGroup,
  getStaticContent,
  IQuestionSection,
  IReferenceOption,
  noEmptyProps,
  STATIC_CONTENT_CONTEXT,
  STATIC_CONTENT_PAYLOAD
} from '@inmarsat-itcloudservices/ui';
import { asyncScheduler, BehaviorSubject, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { first, observeOn, share, switchMap, tap } from 'rxjs/operators';
import { SiteFormService } from '@app/account/services/site-form.service';
import { staticContent } from '@app/app.constants';
import { flatten } from 'ramda';
import { ISiteConfig, ISiteDetail, SiteCategory } from '../../models/site.model';

const MARKET_LABEL = getStaticContent('accounts.create.site.vessel_information.vessel_market', staticContent);
const SECTOR_LABEL = getStaticContent('accounts.create.site.vessel_information.vessel_sector', staticContent);
const CLASS_LABEL = getStaticContent('accounts.create.site.aircraft_information.aircraft_class', staticContent);
const MARKET_PLACEHOLDER = getStaticContent(
  'accounts.create.site.vessel_information.select_vessel_market',
  staticContent
);
const SECTOR_PLACEHOLDER = getStaticContent(
  'accounts.create.site.vessel_information.select_vessel_sector',
  staticContent
);
const CLASS_PLACEHOLDER = getStaticContent(
  'accounts.create.site.aircraft_information.select_aircraft_class',
  staticContent
);

@Component({
  selector: 'inm-site-form',
  templateUrl: './site-form.component.html',
  styleUrls: ['./site-form.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_CONTEXT,
      useValue: 'site_form'
    },
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class SiteFormComponent implements OnInit, OnDestroy {
  @Input() public set siteDetails(value: ISiteDetail) {
    if (value) {
      this.primaryForm.reset();
      this.siteForm.isEditingSite = true;
      this.isEditing = true;
      const model = this.siteForm.mapSiteToFormModel(value);
      this.primaryForm.patchValue(model.primary);

      // Site type cannot be changed for existing sites.
      this.typeCtrl.disable();
      this.siteType$.next(value.type);
      this.siteCategory$.next(model.primary.category);

      this.categoryOptions$.pipe(first()).subscribe(() => {
        if (this.secondaryForm) {
          this.secondaryForm.patchValue(model.secondary);
        }
        this.form.markAsPristine();
      });
    }
  }

  @Input()
  public accountNumber: string;

  @Input()
  public siteName: string;

  public siteTypes = Object.values(SiteCategory);

  public secondaryQuestions: IQuestionSection[];

  public categoryOptions$: Observable<IReferenceOption[]>;

  public subcategoryOptions$: Observable<IReferenceOption[]>;

  public categoryLabel: string;

  public subcategoryLabel: string;

  public categoryPlaceholder: string;

  public subcategoryPlaceholder: string;

  public isEditing: boolean;

  public readonly siteNameCtrl = new UntypedFormControl(null, []);

  public readonly typeCtrl = new UntypedFormControl(SiteCategory.Sea, Validators.required);

  public readonly categoryCtrl = new UntypedFormControl(null, Validators.required);

  public readonly subcategoryCtrl = new UntypedFormControl(null, Validators.required);

  public disableAccoutName = true;

  public primaryForm = new UntypedFormGroup({
    name: this.siteNameCtrl,
    type: this.typeCtrl,
    category: this.categoryCtrl,
    subcategory: this.subcategoryCtrl
  });

  public secondaryForm: UntypedFormGroup;

  public form = new UntypedFormGroup({
    primary: this.primaryForm
  });

  private readonly siteType$ = new BehaviorSubject<SiteCategory>(SiteCategory.Sea);

  private readonly siteCategory$ = new ReplaySubject<string>(1);

  private readonly subscription = new Subscription();

  constructor(private readonly siteForm: SiteFormService, public router: Router) {
    this.configureCategoryControl();
    this.subcategoryOptions$ = this.siteCategory$.pipe(
      observeOn(asyncScheduler),
      switchMap((category) =>
        this.typeCtrl.value === SiteCategory.Sea && category ? this.siteForm.getVesselSubcategories(category) : of(null)
      ),
      tap((options: IReferenceOption[]) => {
        this.subcategoryCtrl.reset({
          disabled: options === null,
          value:
            options && options.some(({ value }) => value === this.subcategoryCtrl.value)
              ? this.subcategoryCtrl.value
              : null
        });
      })
    );
    this.categoryCtrl.valueChanges.subscribe((category) => {
      this.siteCategory$.next(category);
    });
  }

  public ngOnInit(): void {
    if (!this.isEditing) {
      this.siteNameCtrl.setValue(this.siteName);
      this.siteForm.isEditingSite = false;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get siteConfig(): ISiteConfig {
    const {
      name,
      category,
      subcategory
    }: {
      name: string;
      category: string;
      subcategory: string;
    } = this.primaryForm.value;

    const type = this.typeCtrl.value;
    let base = { name };
    if (!this.isEditing) {
      base = noEmptyProps({
        type,
        distributionChannel: 'Direct',
        systemSiteIds: {
          sap: parseInt(this.accountNumber, 10)
        },
        ...base
      });
    }
    const { grossTonnage, personsOnBoard, ...sea } = this.secondaryForm.value;
    const sanitised = {
      ...sea,
      grossTonnage: grossTonnage !== null ? parseInt(grossTonnage, 10) : null,
      personsOnBoard: personsOnBoard !== null ? parseInt(personsOnBoard, 10) : null,
      vesselMarket: category,
      vesselSector: subcategory
    };

    switch (type) {
      case SiteCategory.Land:
        return {
          ...base,
          ...this.secondaryForm.value
        };
      case SiteCategory.Air:
        return {
          ...base,
          ...this.secondaryForm.value,
          aircraftClass: category
        };
      case SiteCategory.Sea:
        // Not using noEmptyProps as it blocks clearing optional values during edit
        return {
          ...base,
          ...sanitised
        };
      default:
        return null;
    }
  }

  private configureCategoryControl(): void {
    this.categoryOptions$ = this.siteType$.pipe(
      observeOn(asyncScheduler),
      tap((type) => {
        this.updateWithType(type);
      }),
      switchMap((type: SiteCategory) =>
        type
          ? {
              Sea: this.siteForm.getVesselCategories(),
              Air: this.siteForm.getAircraftCategories(),
              Land: of(null)
            }[type]
          : of(null)
      ),
      tap((options) => {
        this.categoryCtrl.reset({
          disabled: options === null,
          value:
            options && options.some(({ value }) => value === this.categoryCtrl.value) ? this.categoryCtrl.value : null
        });
      }),
      share()
    );

    this.typeCtrl.valueChanges.subscribe((type) => {
      this.siteType$.next(type);
    });
  }

  private updateWithType(type: SiteCategory, value?: any): void {
    if (type) {
      switch (type) {
        case SiteCategory.Sea: {
          this.categoryLabel = MARKET_LABEL;
          this.subcategoryLabel = SECTOR_LABEL;
          this.categoryPlaceholder = MARKET_PLACEHOLDER;
          this.subcategoryPlaceholder = SECTOR_PLACEHOLDER;
          this.secondaryQuestions = this.siteForm.getSeaQuestions();
          break;
        }
        case SiteCategory.Air: {
          this.categoryLabel = CLASS_LABEL;
          this.categoryPlaceholder = CLASS_PLACEHOLDER;
          this.secondaryQuestions = this.siteForm.getAirQuestions();
          break;
        }
        case SiteCategory.Land: {
          this.secondaryQuestions = this.siteForm.getLandQuestions();
          break;
        }
        default:
          // Something's gone wrong.
          this.secondaryQuestions = null;
          break;
      }

      this.secondaryForm = createFormGroup(flatten(this.secondaryQuestions.map((s) => s.questions)));

      if (value) {
        this.secondaryForm.patchValue(value);
      }

      this.form.setControl('secondary', this.secondaryForm);
    } else {
      this.secondaryQuestions = null;
      this.form.removeControl('secondary');
    }
  }
}
