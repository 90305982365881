import { props, pick } from 'ramda';
import { IReferenceOption } from '@inmarsat-itcloudservices/ui';
import {
  OutboundFeedStatus,
  IOutboundFeedPayload,
  IOutboundFeed,
  OutboundFeedDelivery,
  OutboundFeedFrequency
} from '@shared/models/outbound-feed.model';
import { UntypedFormGroup } from '@angular/forms';

export const FEEDFREQUENCY: Record<string, string[] | null> = {
  CSV: [
    OutboundFeedFrequency.HOURLY,
    OutboundFeedFrequency.DAILY,
    OutboundFeedFrequency.WEEKLY,
    OutboundFeedFrequency.MONTHLY
  ],
  D91: [
    OutboundFeedFrequency.HOURLY,
    OutboundFeedFrequency.DAILY,
    OutboundFeedFrequency.WEEKLY,
    OutboundFeedFrequency.MONTHLY
  ],
  Invoice: [OutboundFeedFrequency.MONTHLY],
  DIR: [OutboundFeedFrequency.MONTHLY],
  Renewals: [OutboundFeedFrequency.MONTHLY],
  Subscriptions: [OutboundFeedFrequency.MONTHLY],
  'Credit Note': [OutboundFeedFrequency.MONTHLY]
};

export const DELIVERY: Record<string, string[] | null> = {
  'CSV.hourly': [OutboundFeedDelivery.SFTP],
  'CSV.daily': [OutboundFeedDelivery.EMAIL, OutboundFeedDelivery.SFTP],
  'CSV.weekly': [OutboundFeedDelivery.EMAIL, OutboundFeedDelivery.SFTP],
  'CSV.monthly': [OutboundFeedDelivery.EMAIL, OutboundFeedDelivery.SFTP],
  'D91.hourly': [OutboundFeedDelivery.SFTP],
  'D91.daily': [OutboundFeedDelivery.EMAIL, OutboundFeedDelivery.SFTP],
  'D91.weekly': [OutboundFeedDelivery.EMAIL, OutboundFeedDelivery.SFTP],
  'D91.monthly': [OutboundFeedDelivery.EMAIL, OutboundFeedDelivery.SFTP],
  'Invoice.monthly': [OutboundFeedDelivery.EMAIL],
  'DIR.monthly': [OutboundFeedDelivery.EMAIL],
  'Renewals.monthly': [OutboundFeedDelivery.EMAIL, OutboundFeedDelivery.SFTP],
  'Subscriptions.monthly': [OutboundFeedDelivery.EMAIL, OutboundFeedDelivery.SFTP],
  'Credit Note.monthly': [OutboundFeedDelivery.EMAIL]
};

export function getFeedFrequency(feedFormat: string, removeHourly = false): { value: string; label: string }[] {
  let getFeedFrequencyValue = FEEDFREQUENCY[feedFormat];
  // Only user with outboundFeed_SFTP permission can select hourly in the feedFrequency
  if (getFeedFrequencyValue && (feedFormat === 'CSV' || feedFormat === 'D91') && !removeHourly) {
    getFeedFrequencyValue = getFeedFrequencyValue.filter(
      (feedFrequencyValue) => feedFrequencyValue !== OutboundFeedFrequency.HOURLY
    );
  }
  return getFeedFrequencyValue ? getFeedFrequencyValue.map((value) => ({ value, label: value })) : null;
}

export function getDelivery(
  feedFrequencyAndDeliveryList: string[],
  removeSFTP = false
): { value: string; label: string }[] {
  // Only user with outboundFeed_SFTP permission can select sftpDelivery in the feedFrequency
  const getDeliveryValue = props(feedFrequencyAndDeliveryList, DELIVERY);
  return getDeliveryValue
    ? !removeSFTP
      ? [...new Set(getDeliveryValue.flat())]
          .filter((deliveryValue) => deliveryValue !== OutboundFeedDelivery.SFTP)
          .map((value) => ({ value, label: value }))
      : [...new Set(getDeliveryValue.flat())].map((value) => ({ value, label: value }))
    : null;
}

export function getFeedTypeValueOrKey(selectedFeedType: any, feedTypleList: any, getFeedValue = true): string {
  const feedTypeValue = getFeedValue
    ? feedTypleList.find((feedTypeKey) => feedTypeKey.value === selectedFeedType)
    : feedTypleList.find((feedTypeKey) => feedTypeKey.label === selectedFeedType);
  if (getFeedValue) {
    return feedTypeValue.label;
  } else {
    return feedTypeValue.value;
  }
}

export function deleteFeedFrequencyAndDelivery(
  obfs: IOutboundFeedPayload[],
  updateOBFId = false
): IOutboundFeedPayload[] {
  obfs.forEach((obf) =>
    Object.keys(obf).forEach((key) => {
      if (obf[key] === null && updateOBFId) {
        delete obf[key];
      }
      delete obf.feedFrequency;
      delete obf.delivery;
      if (updateOBFId) {
        //Needs to remove inheritance obf id, the create action should generate a new obf Id
        delete obf._id;
      }
    })
  );
  return obfs;
}

export function setupOutboundFeedPayload(
  formValue: IOutboundFeedPayload[],
  feedTypleList: IReferenceOption[],
  accountNumber: string,
  accountName: string,
  arAccountNumber: string,
  cleNumber: string
): any {
  const obfs = formValue.map((obf) => {
    return {
      ...obf,
      feedType: getFeedTypeValueOrKey(obf.feedType, feedTypleList),
      accountNumber: accountNumber,
      arAccount: arAccountNumber,
      accountName: accountName,
      legalEntityCode: cleNumber,
      inheritanceId: obf._id,
      status: OutboundFeedStatus.ACTIVE,
      hourly: obf.feedFrequency && obf.feedFrequency.indexOf(OutboundFeedFrequency.HOURLY) > -1,
      daily: obf.feedFrequency && obf.feedFrequency.indexOf(OutboundFeedFrequency.DAILY) > -1,
      weekly: obf.feedFrequency && obf.feedFrequency.indexOf(OutboundFeedFrequency.WEEKLY) > -1,
      monthly: obf.feedFrequency && obf.feedFrequency.indexOf(OutboundFeedFrequency.MONTHLY) > -1,
      emailDelivery: obf.delivery && obf.delivery.indexOf(OutboundFeedDelivery.EMAIL) > -1,
      sftpDelivery: obf.delivery && obf.delivery.indexOf(OutboundFeedDelivery.SFTP) > -1,
      ...(obf.directory &&
        !obf.delivery.includes(OutboundFeedDelivery.SFTP) && {
          directory: null
        }),
      ...(obf.emailAddress &&
        !obf.delivery.includes(OutboundFeedDelivery.EMAIL) && {
          emailAddress: null,
          ccEmailAddress: null
        })
    };
  });
  return deleteFeedFrequencyAndDelivery(obfs, true);
}

export function updateOBFPayload(fromGroupArray: any): any {
  const obfs = fromGroupArray.map((obf: UntypedFormGroup) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const touchedFields = Object.fromEntries(Object.entries(obf.controls).filter(([_, value]) => value.touched));
    return {
      ...new UntypedFormGroup(touchedFields).value,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _id: obf?.value?._id,
      ...(touchedFields?.feedFrequency && {
        hourly: obf?.value?.feedFrequency.indexOf(OutboundFeedFrequency.HOURLY) > -1
      }),
      ...(touchedFields?.feedFrequency && {
        daily: obf?.value?.feedFrequency.indexOf(OutboundFeedFrequency.DAILY) > -1
      }),
      ...(touchedFields?.feedFrequency && {
        weekly: obf?.value?.feedFrequency.indexOf(OutboundFeedFrequency.WEEKLY) > -1
      }),
      ...(touchedFields?.feedFrequency && {
        monthly: obf?.value?.feedFrequency.indexOf(OutboundFeedFrequency.MONTHLY) > -1
      }),
      ...(touchedFields?.delivery && {
        emailDelivery: obf?.value?.delivery.indexOf(OutboundFeedDelivery.EMAIL) > -1
      }),
      ...(touchedFields?.delivery && {
        sftpDelivery: obf?.value?.delivery.indexOf(OutboundFeedDelivery.SFTP) > -1
      }),
      ...(obf.value.directory &&
        !obf.value.delivery.includes(OutboundFeedDelivery.SFTP) && {
          directory: null
        }),
      ...(obf.value.emailAddress &&
        !obf.value.delivery.includes(OutboundFeedDelivery.EMAIL) && {
          emailAddress: null,
          ccEmailAddress: null
        })
    };
  });
  return deleteFeedFrequencyAndDelivery(obfs);
}

export function convertTimeToFeedFrequency(obs: IOutboundFeed): string[] {
  const feedFrequencyObj = pick(
    [
      OutboundFeedFrequency.HOURLY,
      OutboundFeedFrequency.DAILY,
      OutboundFeedFrequency.WEEKLY,
      OutboundFeedFrequency.MONTHLY
    ],
    obs
  );
  return Object.keys(feedFrequencyObj).filter((item) => {
    return feedFrequencyObj[item] === true;
  });
}

export function convertdevlieryTodeliveryArray(obs: IOutboundFeed): string[] {
  const deliveryObj = pick(['emailDelivery', 'sftpDelivery'], obs);
  return Object.keys(deliveryObj)
    .filter((item) => {
      return deliveryObj[item] === true;
    })
    .map((x) => x.slice(0, -8));
}

export function convertOutboundFeedDataStructure(
  formValue: IOutboundFeed[],
  feedTypeList: IReferenceOption[]
): IOutboundFeed[] {
  const obfs = formValue.map((obf) => {
    return {
      ...obf,
      feedType: getFeedTypeValueOrKey(obf.feedType, feedTypeList, false),
      feedFrequency: convertTimeToFeedFrequency(obf),
      delivery: convertdevlieryTodeliveryArray(obf)
    };
  });
  obfs.forEach((obf) =>
    Object.keys(obf).forEach(() => {
      delete obf.hourly;
      delete obf.daily;
      delete obf.weekly;
      delete obf.monthly;
      delete obf.emailDelivery;
      delete obf.sftpDelivery;
    })
  );
  return obfs;
}

/**
 * When user creates a new feed, we will need to check to see
 * if there is alrady a feed that exists for the same feed format, feed type, feed frequencies and feed delivery
 * To make this data more easily accessible in the UI, we flatten it into a
 * map(FeedFormat - feedType -> [{feed frequencies, feed devliery},...])
 * */

export function combineOutboundFeedWithSameFeedFormatAndType(ExistingOutboundFeeds: IOutboundFeed[]): any {
  return ExistingOutboundFeeds.reduce((eachOutboundFeed, currentItem) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    if (!eachOutboundFeed[`${currentItem.feedType}-${currentItem.feedFormat}`]) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      eachOutboundFeed[`${currentItem.feedType}-${currentItem.feedFormat}`] = [];
    }
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    eachOutboundFeed[`${currentItem.feedType}-${currentItem.feedFormat}`].push(
      pick(
        [
          OutboundFeedFrequency.HOURLY,
          OutboundFeedFrequency.DAILY,
          OutboundFeedFrequency.WEEKLY,
          OutboundFeedFrequency.MONTHLY,
          'emailDelivery',
          'sftpDelivery'
        ],
        currentItem
      )
    );
    return eachOutboundFeed;
  }, {});
}
