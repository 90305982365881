import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  STATIC_CONTENT_PAYLOAD,
  IReferenceOption,
  getStaticContent,
  IconName,
  getOBFFeedTypeOptions
} from '@inmarsat-itcloudservices/ui';
import { staticContent, DEFAULT_PAGE_SIZE } from '@app/app.constants';
import { IState } from '@app/shared-store';
import { Store } from '@ngrx/store';
import * as OutboundFeedActions from '@app/shared-store/outbound-feed/outbound-feed.actions';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  setupOutboundFeedPayload,
  combineOutboundFeedWithSameFeedFormatAndType
} from '@app/outbound-feed/outbound-feed-helpers';
import { Subscription, combineLatest } from 'rxjs';
import {
  getAccountDetails,
  getChildAccounts,
  getOutboundFeedsByARAccount,
  getSiteDetailsByAccount
} from '@app/shared-store/account/account.selectors';
import { AccountGroup, IAccountDetails, AccountStatus } from '@app/shared/models/account.model';
import {
  OutboundFeedStatus,
  IOutboundFeed,
  OutboundFeedFormStatus,
  MandatoryOutboundFeed
} from '@app/shared/models/outbound-feed.model';
import * as AccountActions from '@shared-store/account/account.actions';
import { AccountAddOutboundFeedStepComponent } from '@app/account/components/account-add-outbound-feed-step/account-add-outbound-feed-step.component';
import { reject, difference } from 'ramda';
import { shouldUpdateStatus } from '@app/account/account-helpers';
import { getAllOBFsAssociatedWithAccount } from '@app/shared-store/outbound-feed/outbound-feed.selectors';

const AR_ACCOUNT_CHECKBOX_LABEL = getStaticContent('common.copy_obf_to_bp', staticContent);

const BP_ACCOUNT_CHECKBOX_LABEL = getStaticContent('common.copy_all', staticContent);

@Component({
  selector: 'inm-add-outbound-feed',
  templateUrl: './add-outbound-feed.component.html',
  styleUrls: ['./add-outbound-feed.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class AddOutboundFeedComponent implements OnInit, OnDestroy {
  public form = new UntypedFormGroup({
    feedType: new UntypedFormControl(null, Validators.required),
    feedFormat: new UntypedFormControl(null, Validators.required),
    sendEmptyFile: new UntypedFormControl(null),
    feedFrequency: new UntypedFormControl(null, Validators.required),
    zip: new UntypedFormControl(null),
    delivery: new UntypedFormControl(null, Validators.required),
    directory: new UntypedFormControl(null),
    zipPassword: new UntypedFormControl(null),
    notes: new UntypedFormControl(null),
    emailAddress: new UntypedFormControl(null),
    ccEmailAddress: new UntypedFormControl(null)
  });

  @ViewChild('outboundFeedForm')
  public outboundFeedForm: AccountAddOutboundFeedStepComponent;

  public feedType: IReferenceOption[];

  public inheritance = true;

  public hasBPAccount = false; // Used in AR level, to check If AR has BP associated.

  public allowToAddOneOutboundFeed = false; //Add obf in AR account or add obf in BP account if no available AR account allow to pull

  public accountNumber: string;

  public legalEntityCode: string;

  public accountGroup: string;

  public post: boolean;

  public showOutboundFeedsByARAccount = false;

  public arAccountNumber: string;

  public accountDetails: IAccountDetails;

  public hasSiteAssigned = false;

  public allowedTypesExistingOutboundFeeds;

  public allowedTypesNewOutboundFeeds;

  public readonly iconNames = IconName;

  private readonly subscription = new Subscription();

  public currentOBFList: IOutboundFeed[];

  public checkARContainsMandatoryOBFs = false;

  public showErrorMessage = false;

  constructor(public bsModalRef: BsModalRef, private readonly store: Store<IState>) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.store.select(getAccountDetails).subscribe((accountDetail) => {
        if (accountDetail) {
          this.accountNumber = accountDetail.accountNumber;
          this.legalEntityCode = accountDetail.legalEntityCode;
          this.accountGroup = accountDetail.accountGroup;
          this.post = accountDetail.post;
          this.arAccountNumber = accountDetail.salesOrgs[0]?.postingAccount;
          this.accountDetails = accountDetail;
          this.getAllAccountAssociatedOutboundFeeds();
        }
      })
    );

    this.getFeedTypeList();
    if (!this.isPostingAccount()) {
      // BP account details page, pull AR and BP Outbound Feed. If AR has available Outbound Feed, show checkbox
      // Needs to pull BP Outbound Feed to avoid add duplicate Outbound Feed record.
      this.pullARAccountOBFs(); // user landed on the bp level, doens't need to call api gain
      this.getParentOBFs();
    } else {
      // AR account details page, pull active or draft BP acounts; If AR contains active or draft BP account, then enable inheritance.
      this.pullActiveBPaccounts();
      this.getChildAccount();

      this.allowToAddOneOutboundFeed = true;
    }
    this.getAssociatedSite();
    this.getCurrentAccountOBFs();
  }

  public isPostingAccount(): boolean {
    return (
      this.accountGroup === AccountGroup.INMA ||
      this.accountGroup === AccountGroup.INM7 ||
      this.accountGroup === AccountGroup.IM15 ||
      this.accountGroup === AccountGroup.IM16 ||
      this.accountGroup === AccountGroup.INM2 ||
      this.accountGroup === AccountGroup.INM5 ||
      this.accountGroup === AccountGroup.INMI ||
      this.accountGroup === AccountGroup.INDE ||
      (this.accountGroup === AccountGroup.INM1 && this.post)
    );
  }

  public createOutboundFeed(): void {
    if (
      (this.allowToAddOneOutboundFeed && this.form.valid) ||
      (!this.allowToAddOneOutboundFeed &&
        (this.outboundFeedForm?.form?.valid || this.outboundFeedForm?.form?.status === OutboundFeedFormStatus.DISABLED))
    ) {
      const obfARAccountNumber = this.isPostingAccount() ? this.accountNumber : this.arAccountNumber;
      const formValue = this.allowToAddOneOutboundFeed
        ? [this.form.value]
        : this.outboundFeedForm?.form?.getRawValue().obfs;
      const payload = setupOutboundFeedPayload(
        formValue,
        this.feedType,
        this.accountNumber,
        this.accountDetails.name,
        obfARAccountNumber,
        this.legalEntityCode
      );
      const checkAccountStatus = shouldUpdateStatus(
        this.accountDetails,
        null,
        this.hasSiteAssigned,
        this.isAccountHasMandatoryOBFs(this.currentOBFList)
      );

      const inheritanceIds: string[] = Object.values(
        payload.filter((item) => item.inheritanceId).map((x) => x.inheritanceId as string)
      );
      const inheritancePayload = {
        inheritanceIds: inheritanceIds,
        billingAccount: this.accountNumber,
        accountName: this.accountDetails.name,
        arAccount: obfARAccountNumber
      };

      this.allowedTypesNewOutboundFeeds = combineOutboundFeedWithSameFeedFormatAndType(payload);

      if (this.toFindDuplicateOutboundFeed().length) {
        this.showErrorMessage = true;

        this.form.markAllAsTouched();
        this.outboundFeedForm?.form?.markAllAsTouched();
      } else {
        this.showErrorMessage = false;
        this.store.dispatch(
          OutboundFeedActions.createOutboundFeed({
            obfs: payload.filter((item) => !item.inheritanceId),
            inheritancePayload,
            updateAccountStauts: checkAccountStatus,
            withInheriance: this.inheritance,
            updateINMAStatus: this.checkARContainsMandatoryOBFs && this.isAccountHasMandatoryOBFs(this.currentOBFList)
          })
        );
      }
    } else {
      this.form.markAllAsTouched();
      this.outboundFeedForm?.form?.markAllAsTouched();
    }
  }

  public getFeedTypeList(): void {
    this.subscription.add(
      this.store.select(getOBFFeedTypeOptions).subscribe((feedTypeList) => {
        if (feedTypeList) {
          this.feedType = feedTypeList;
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public inheritanceToAllBPAccount(): void {
    if (this.isPostingAccount()) {
      this.inheritance = !this.inheritance;
    }
  }

  // Inheritance function: Enable copy_obf_to_bp checkbox to allow setup inheritance if AR contains BP.
  public getChildAccount(): any {
    this.subscription.add(
      this.store.select(getChildAccounts).subscribe((result) => {
        if (result.totalCount === 1) {
          // needs to check if the record is AR or BP.
          result.items[0].accountNumber === this.accountNumber
            ? (this.hasBPAccount = false)
            : (this.hasBPAccount = true);
        } else {
          result.totalCount ? (this.hasBPAccount = true) : (this.hasBPAccount = false);
        }
        if (
          this.accountDetails.accountGroup === AccountGroup.INMA &&
          this.accountDetails.status === AccountStatus.INCOMPLETE &&
          result &&
          result.items[0]?.accountGroup === AccountGroup.INMB &&
          result.items[0]?.status !== AccountStatus.INCOMPLETE &&
          result.items[0]?.primaryBilling
        ) {
          // When User add OBFs in the Cloned INMB account, but not INMA. INMA status can't update.
          this.checkARContainsMandatoryOBFs = true;
        }
      })
    );
  }

  public getCheckboxText(): string {
    if (this.isPostingAccount()) {
      return AR_ACCOUNT_CHECKBOX_LABEL;
    }

    return BP_ACCOUNT_CHECKBOX_LABEL;
  }

  // If AR account doesn't have OBFs, then portal doesn't need to show the checkbox.
  private pullARAccountOBFs() {
    const queryParams = {
      accountNumber: this.arAccountNumber,
      status: OutboundFeedStatus.ACTIVE,
      legalEntityCode: this.legalEntityCode
    };
    this.store.dispatch(AccountActions.pullARAccountOBFS(queryParams));
  }

  // If AR doesn't have active/draft BP, then portal doesn't need to show the checkbox.
  private pullActiveBPaccounts() {
    const payload: any = {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
      post: false,
      active: true
    };
    this.store.dispatch(AccountActions.loadActiveChildAccounts(payload, this.arAccountNumber));
  }

  // If AR account doesn't have OBFs, then portal doesn't need to show the checkbox.
  public getParentOBFs(): any {
    this.subscription.add(
      combineLatest([
        this.store.select(getOutboundFeedsByARAccount),
        this.store.select(getAccountDetails),
        this.store.select(getAllOBFsAssociatedWithAccount)
      ]).subscribe(([ARAccountOBFs, accountDetails, currentOBFList]) => {
        if (ARAccountOBFs.length === 0) {
          this.allowToAddOneOutboundFeed = true;
          this.hasBPAccount = false; //AR account doesn't have OBFS, no copy all function needed
        }
        if (ARAccountOBFs.length && currentOBFList.items) {
          this.checkAvailableAROBF(currentOBFList.items, ARAccountOBFs);

          if (
            accountDetails &&
            accountDetails?.accountGroup === AccountGroup.INMB &&
            accountDetails?.status === AccountStatus.INCOMPLETE &&
            accountDetails?.primaryBilling
          ) {
            this.checkARContainsMandatoryOBFs = this.isAccountHasMandatoryOBFs(ARAccountOBFs);
          }
        }
      })
    );
  }

  // only the feeds that have not already been copied will appear
  public checkAvailableAROBF(currentAccountOBFs: IOutboundFeed[], ARAccountOBFs: IOutboundFeed[]): void {
    const getInheritanceIdList = currentAccountOBFs.filter((x) => x.inheritanceId).map((x) => x.inheritanceId);
    const removeDuplicateOBF = (AROBF) => getInheritanceIdList.indexOf(AROBF._id) >= 0;
    const result = reject(removeDuplicateOBF, ARAccountOBFs);
    if (result.length) {
      this.allowToAddOneOutboundFeed = false; // there is available AR obf for BP to copy from
    } else {
      this.allowToAddOneOutboundFeed = true;
      this.hasBPAccount = false; // BP account, doesn't need to set hasBPAccount value.
    }
  }

  private getAssociatedSite() {
    this.subscription.add(
      this.store.select(getSiteDetailsByAccount).subscribe((siteDetails) => {
        siteDetails && siteDetails?.length ? (this.hasSiteAssigned = true) : (this.hasSiteAssigned = false);
      })
    );
  }

  public getCurrentAccountOBFs(): void {
    this.subscription.add(
      this.store.select(getAllOBFsAssociatedWithAccount).subscribe((currentAccountOBFs) => {
        if (currentAccountOBFs.items) {
          this.currentOBFList = currentAccountOBFs.items;
          if (this.currentOBFList.length) {
            this.allowedTypesExistingOutboundFeeds = combineOutboundFeedWithSameFeedFormatAndType(this.currentOBFList);
          }
        }
      })
    );
  }

  public isAccountHasMandatoryOBFs(existingOBFlist: IOutboundFeed[]): boolean {
    const combinedOBFList = this.outboundFeedForm?.form?.value?.obfs
      ? existingOBFlist.concat(this.outboundFeedForm.form.value.obfs)
      : existingOBFlist.concat(this.form.value);
    return (
      combinedOBFList.some((vendor) => vendor['feedFormat'] === MandatoryOutboundFeed.INVOICE) &&
      combinedOBFList.some((vendor) => vendor['feedFormat'] === MandatoryOutboundFeed.CREDIT_NOTE)
    );
  }

  public getAllAccountAssociatedOutboundFeeds(): void {
    const queryParams = {
      accountNumber: this.accountNumber,
      legalEntityCode: this.legalEntityCode,
      status: OutboundFeedStatus.ACTIVE
    };
    this.store.dispatch(OutboundFeedActions.getAllOBFsAssociatedWithAccount(queryParams));
  }

  public toFindDuplicateOutboundFeed(): any[] {
    let duplicates = [];
    Object.keys(this.allowedTypesNewOutboundFeeds).forEach((key) => {
      if (this.allowedTypesNewOutboundFeeds[key].length > 1) {
        const combineDuplicateOutboundFeed = (
          Array.from(new Set(this.allowedTypesNewOutboundFeeds[key].map(JSON.stringify))) as any
        ).map(JSON.parse);

        duplicates =
          !duplicates?.length && combineDuplicateOutboundFeed.length === this.allowedTypesNewOutboundFeeds[key].length
            ? []
            : combineDuplicateOutboundFeed;
      }
      if (
        !duplicates?.length &&
        this.allowedTypesExistingOutboundFeeds &&
        this.allowedTypesExistingOutboundFeeds[key] !== undefined
      ) {
        duplicates =
          !duplicates?.length &&
          difference(this.allowedTypesNewOutboundFeeds[key], this.allowedTypesExistingOutboundFeeds[key]).length ===
            this.allowedTypesNewOutboundFeeds[key].length
            ? []
            : this.allowedTypesNewOutboundFeeds[key];
      }
    });
    return duplicates;
  }
}
