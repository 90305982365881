import { Component, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NgControl,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {
  IReferenceOption,
  STATIC_CONTENT_PAYLOAD,
  TypeaheadComponent,
  loadReferenceByRefType,
  GenericRefDataType,
  getAirportCodeOptions,
  getStateProvinceOptions,
  getFullCountryOptions,
  getLoaderDataByType,
  getRefDataByCodeOptions
} from '@inmarsat-itcloudservices/ui';
import { Store } from '@ngrx/store';
import { Observable, Subscription, of, Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { staticContent } from '@app/app.constants';
import { IState } from '@app/shared-store';
import { getCleRelatedAddresses } from '@app/shared-store/cle/cle.selectors';
import { map } from 'rxjs/operators';
import { IAddress, AddressType, IAddressOption } from '../../models/address.model';

@Component({
  selector: 'inm-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class AddressFormComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  public subs = new Subscription();

  public countryOptions: IReferenceOption[];

  public stateProvinces: IReferenceOption[];

  public airportCodeOptions: IReferenceOption[];

  public postCodeHint: string;

  public checked: boolean;

  public form: UntypedFormGroup;

  public showCheckbox: boolean;

  public defaultPostCode = false; // when disabled adddress form, postcode value comes from parent, no need to recheck postcode vadlidation rule

  public onTouched: () => void;

  @Input() public useExistingAddress: boolean;

  @Input() public showAviationCheckbox = false;

  public defaultAviationCheckboxValue = false;

  @ViewChild('countryDropdownTypeahead')
  public typeaheadComponent: TypeaheadComponent;

  @Input() public addressDetails: IAddress;

  public results$;

  public search$ = new Subject<string>();

  public addressTypes = AddressType;

  public addressOptions$: Observable<IAddressOption[]>;

  public allowCopyEmergencyAddress = false;

  public provinceLoader$: Observable<any>;

  constructor(
    fb: UntypedFormBuilder,
    public readonly store: Store<IState>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.form = fb.group({
      type: [null, []],
      address: [null, []],
      country: [null, Validators.required],
      isoCountryCode: [null, Validators.required],
      city: [null, Validators.required],
      addressLine1: [null, Validators.required],
      addressLine2: [null, []],
      addressLine3: [null, []],
      dist: [null, []],
      isoStateProvince: [null, []],
      stateProvince: [null, []],
      //   careOfName: [null, []], Enable it in 3B
      postalCode: [null, []],
      airportCode: [null, []]
    });
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    this.provinceLoader$ = this.store.select(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      getLoaderDataByType(`${GenericRefDataType.StateProvince}.${this.form.controls.isoCountryCode}` as any)
    );
    if (this.ngControl) {
      const control = this.ngControl.control;
      control.setValidators(() => this.validate());
      control.updateValueAndValidity();

      const original = control.markAsTouched;

      control.markAsTouched = (...args: any[]) => {
        this.form.markAllAsTouched();
        original.call(args);
      };
    }

    this.subs.add(
      this.store.select(getFullCountryOptions).subscribe((countries) => {
        this.countryOptions = countries;
      })
    );

    this.loadProvinceByCountry();

    this.subs.add(
      this.store.select(getStateProvinceOptions).subscribe((data) => {
        this.stateProvinces = data;
        if (this.stateProvinces && this.stateProvinces.length > 0) {
          this.form.controls.stateProvince.setValidators([Validators.required]);
          if (
            this.allowCopyEmergencyAddress &&
            this.addressDetails &&
            this.addressDetails.stateProvince &&
            this.form.controls?.stateProvince?.value === null
          ) {
            // When user select emergency address with different country, needs to patch the form with loaded new province value
            const selectUpdatedProvince: IReferenceOption = this.stateProvinces.find(
              (country) => country.label === this.addressDetails.stateProvince
            );
            if (selectUpdatedProvince) {
              this.patchAddressForm();
            }
          }
        } else {
          this.form.controls.stateProvince.clearValidators();
          this.form.controls.stateProvince.setValue(null);
        }
      })
    );

    this.subs.add(
      this.store.select(getAirportCodeOptions).subscribe((data) => {
        this.airportCodeOptions = data.map(({ item, ...label }) => label);
        this.results$ = of({
          items: this.airportCodeOptions.slice(0, 20),
          totalCount: this.airportCodeOptions.length,
          loading: false
        });
      })
    );

    this.search$.pipe(debounceTime(400), distinctUntilChanged()).subscribe((search: string) => {
      this.results$ = of({
        items: this.airportCodeOptions
          .filter((item) => Object.keys(item).some((key) => item[key].toLowerCase().includes(search.toLowerCase())))
          .slice(0, 20),
        totalCount: this.airportCodeOptions.filter((item) =>
          Object.keys(item).some((key) => item[key].toLowerCase().includes(search.toLowerCase()))
        ).length,
        loading: false
      });
    });

    this.subscribeToCountry();
    this.subscribeToStateProvince();
    this.patchAddressForm();
    this.copyEmergencyAddress();
    if (this.showAviationCheckbox) {
      this.setValidationForAirportCode(this.defaultAviationCheckboxValue);
    }
  }

  public search(search: string): void {
    this.search$.next(search);
  }

  public setValidationForAirportCode(aviation: boolean): void {
    if (aviation) {
      this.form.controls.airportCode.setValidators([Validators.required]);
      this.form.controls.airportCode.updateValueAndValidity();
    } else {
      this.form.controls.airportCode.clearValidators();
      this.form.controls.airportCode.updateValueAndValidity();
      this.form.controls.airportCode.setValue(null);
    }
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public writeValue(obj: any): void {
    if (obj) {
      this.form.setValue(obj, { emitEvent: false });
    }
  }

  public registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public validate(): ValidationErrors {
    return this.form.valid ? null : { editFolder: false };
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this.form.statusChanges.subscribe(fn);
  }

  public toggleFormEdit(disableForm: boolean): void {
    if (disableForm) {
      this.form.enable();
      this.defaultPostCode = false;
      this.resetForm();
    } else {
      this.form.disable();
      this.defaultPostCode = true;
      this.patchAddressForm();
    }
  }

  public toggleAviation(aviation: boolean): void {
    this.defaultAviationCheckboxValue = !this.defaultAviationCheckboxValue;
    this.setValidationForAirportCode(aviation);
  }

  private subscribeToCountry() {
    this.subs.add(
      this.form.controls.isoCountryCode.valueChanges.subscribe((countryCode) => {
        const selectedCountry: IReferenceOption = this.countryOptions.find((country) => country.value === countryCode);
        if (selectedCountry && !this.defaultPostCode) {
          if (!this.allowCopyEmergencyAddress) {
            this.form.controls.postalCode.patchValue(null);
          }
          this.form.controls.country.setValue(selectedCountry.label);
          if (selectedCountry.item) {
            if (selectedCountry.item.postCodeRegex) {
              this.form.controls.postalCode.setValidators([
                Validators.required,
                Validators.pattern(selectedCountry.item.postCodeRegex)
              ]);
              this.postCodeHint = selectedCountry.item.postCodeHint;
              this.form.controls.postalCode.updateValueAndValidity();
            } else {
              this.form.controls.postalCode.clearValidators();
              this.postCodeHint = null;
              this.form.controls.postalCode.updateValueAndValidity();
            }
          }
        }
        if (countryCode !== null) {
          this.store.dispatch(loadReferenceByRefType(GenericRefDataType.StateProvince, countryCode));
          this.loadProvinceByCountry();
        }
      })
    );
  }

  private subscribeToStateProvince() {
    this.form.get('stateProvince').valueChanges.subscribe((val) => {
      const selectProvince: IReferenceOption = this.stateProvinces.find((country) => country.label === val);
      if (selectProvince) {
        this.form.controls.isoStateProvince.setValue(selectProvince.value);
      }
    });
  }

  private patchAddressForm() {
    this.defaultPostCode = false;
    if (this.addressDetails && this.useExistingAddress) {
      this.defaultPostCode = true;
      this.form.patchValue({
        ...this.addressDetails
      });
    }
    if (this.addressDetails && this.allowCopyEmergencyAddress) {
      // set selected address
      this.form.get('country').setValue(this.addressDetails.country);
      this.form.get('isoCountryCode').setValue(this.addressDetails.isoCountryCode);
      this.form.get('city').setValue(this.addressDetails.city);
      this.form.get('addressLine1').setValue(this.addressDetails.addressLine1);
      this.form.get('addressLine2').setValue(this.addressDetails.addressLine2);
      this.form.get('addressLine3').setValue(this.addressDetails.addressLine3);
      this.form.get('dist').setValue(this.addressDetails.dist);
      this.form.get('isoStateProvince').setValue(this.addressDetails.isoStateProvince);
      this.form.get('stateProvince').setValue(this.addressDetails.stateProvince);
      this.form.get('postalCode').setValue(this.addressDetails.postalCode);
      this.typeaheadComponent.typeaheadCtrl.setValue(this.form.controls.country.value);
      return;
    }
    if (this.typeaheadComponent !== undefined && !this.allowCopyEmergencyAddress) {
      this.typeaheadComponent.typeaheadCtrl.setValue(this.form.controls.country.value);
    }
  }

  private loadProvinceByCountry() {
    this.subs.add(
      this.store
        .select(getRefDataByCodeOptions(GenericRefDataType.StateProvince, this.form.controls.isoCountryCode.value))
        .subscribe((data) => {
          this.stateProvinces = data;
          if (this.stateProvinces && this.stateProvinces.length > 0) {
            this.form.controls.stateProvince.setValidators([Validators.required]);
          } else {
            this.form.controls.stateProvince.clearValidators();
            this.form.controls.stateProvince.setValue(null);
            this.form.controls.isoStateProvince.setValue(null);
          }
        })
    );
  }

  private resetForm() {
    this.form.patchValue({
      country: null,
      isoCountryCode: null,
      city: null,
      addressLine1: null,
      addressLine2: null,
      addressLine3: null,
      dist: null,
      isoStateProvince: null,
      stateProvince: null,
      // careOfName: null, Enable it in 3B
      postalCode: null,
      airportCode: null
    });
  }

  public copyEmergencyAddress(): void {
    // user can pull emergency address under the CLE
    this.addressOptions$ = this.store.select(getCleRelatedAddresses).pipe(
      map((addresses) => {
        addresses = addresses.filter((address) => address.type === this.addressTypes.EMERGENCY);

        return addresses.map((address: IAddress) => {
          const city = address.city ? `, ${address.city}` : '';
          const addressLine1 = address.addressLine1 ? `, ${address.addressLine1}` : '';
          const addressLine2 = address.addressLine2 ? `, ${address.addressLine2}` : '';
          const addressLine3 = address.addressLine3 ? `, ${address.addressLine3}` : '';
          return {
            label: `${address.country || ''} ${city} ${addressLine1} ${addressLine2} ${addressLine3} `,
            value: address
          };
        });
      })
    );

    this.form.get('address').valueChanges.subscribe((address) => {
      if (this.form.value.type === AddressType.EMERGENCY) {
        this.allowCopyEmergencyAddress = true;
        this.addressSelected(address);
      }
    });
  }

  public addressSelected(address: IAddress): void {
    if (address === null) {
      this.addressDetails = null;
      this.resetForm();
      this.typeaheadComponent.typeaheadCtrl.setValue(null);
    } else {
      this.addressDetails = address;
      this.patchAddressForm();
    }
  }
}
