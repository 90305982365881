import { Component, OnDestroy } from '@angular/core';
import {
  STATIC_CONTENT_PAYLOAD,
  getStaticContent,
  IconName,
  IconColor,
  STATIC_CONTENT_CONTEXT
} from '@inmarsat-itcloudservices/ui';
import { staticContent, DEFAULT_PAGE_SIZE } from '@app/app.constants';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { IState } from '@app/shared-store';
import * as ChangeRequestActions from '@app/shared-store/change-request/change-request.actions';
import * as CLEActions from '@shared-store/cle/cle.actions';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn } from '@angular/forms';
import * as AccountActions from '@shared-store/account/account.actions';
import { ICLEQuery } from '@app/shared/models/cle.model';
import { getCLEPage } from '@app/shared-store/cle/cle.selectors';
import { getAccountDetails, getParentAccountsList } from '@app/shared-store/account/account.selectors';
import { IAccountDetails, AccountGroup } from '@app/shared/models/account.model';
import { difference } from 'ramda';
import {
  IChangeRequestOptions,
  changeRequestOptions,
  IChangeRequestEntityType,
  IChangeRequestActions,
  AccountActionRelatedChangeRequest,
  IChangeRequestType
} from '../../../shared/models/change-request.model';
import {
  getChangeRequestActionValue,
  getChangeRequestDetail,
  getChangeRequestList
} from '../../../shared-store/change-request/change-request.selectors';

const APPROVE_CHANGE_REQUEST_MODAL = getStaticContent('change_request.action_modal', staticContent);
const DEFAULT_APPROVED_COMMENTS_MESSAGE = getStaticContent('change_request.comments.approved', staticContent);
const RESBUMIT_CLE_CHANGE = getStaticContent('customers.cle.details.request_resubmit', staticContent);
const RESBUMIT_ACCOUNT_CHANGE = getStaticContent('accounts.details.request_resubmit', staticContent);
const CREATE_NEW_CLE_REQUEST = getStaticContent('customers.cle.details.request_new_changeRequest', staticContent);
const CREATE_NEW_ACCOUNT_REQUEST = getStaticContent('customers.cle.details.request_new_changeRequest', staticContent);

@Component({
  selector: 'inm-change-request-modal',
  templateUrl: './change-request-modal.component.html',
  styleUrls: ['./change-request-modal.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_CONTEXT,
      useValue: 'change_request.details.metadata'
    },
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class ChangeRequestModalComponent implements OnDestroy {
  constructor(public readonly store: Store<IState>, public bsModalRef: BsModalRef) {
    this.subscription.add(
      this.store.select(getChangeRequestActionValue).subscribe((actionValue) => {
        if (actionValue) {
          this.actionValue = actionValue;
          this.defaultComments =
            this.actionValue.requestOptionValue === IChangeRequestOptions.APPROVE
              ? DEFAULT_APPROVED_COMMENTS_MESSAGE
              : null;
          this.showMovedTo = this.actionValue?.reSubmitPayload?.changeRequest.requestType === IChangeRequestType.MOVE;
        }
      })
    );

    this.subscription.add(
      this.store.select(getChangeRequestDetail).subscribe((changeRequestDetails) => {
        if (changeRequestDetails) {
          this.requestId = changeRequestDetails._id;
          this.requesterToReceiveEmailNotification = changeRequestDetails.requester;
        }
      })
    );
    if (this.showMovedTo) {
      this.loadAccountDetailForAccountMove();
    }

    this.commentsCtrl = new UntypedFormControl(this.defaultComments, Validators.required);
    this.movedToCtrl = new UntypedFormControl(null);
    this.form = new UntypedFormGroup({
      comments: this.commentsCtrl,
      movedTo: this.movedToCtrl
    });

    this.getChangeRequestList();
  }

  private readonly subscription = new Subscription();

  public actionValue = null;

  public requestId: string;

  public requiredNewSalesCode = null;

  public requesterToReceiveEmailNotification = null;

  public movedTo: string;

  public accountDetail: IAccountDetails;

  public defaultComments = DEFAULT_APPROVED_COMMENTS_MESSAGE;

  public options = {
    title: APPROVE_CHANGE_REQUEST_MODAL.title,
    message: APPROVE_CHANGE_REQUEST_MODAL.message,
    confirmLabel: APPROVE_CHANGE_REQUEST_MODAL.confirmLabel,
    cancelLabel: APPROVE_CHANGE_REQUEST_MODAL.cancelLabel,
    allowCancel: true,
    showConfirmCheckbox: true,
    closeIcon: true
  };

  public readonly commentsCtrl: UntypedFormControl;

  public readonly movedToCtrl: UntypedFormControl;

  public form: UntypedFormGroup;

  public readonly iconNames = IconName;

  public iconColors = IconColor;

  public allowMovedToList: {
    loaded: boolean;
    items: any[];
  };

  public showMovedTo = false;

  public showUnmatahedSalesOrgs = false;

  public parentAccountGroup = [];

  public Approve(): void {
    if (this.form.valid) {
      const apiAction = Object.values(changeRequestOptions).filter(
        (item) => item.label === this.actionValue.requestOptionValue
      )[0].apiAction;
      const query = {
        requestId: this.requestId ?? this.actionValue.requestId,
        action: apiAction,
        comment: this.form.value.comments
      };
      if (
        this.actionValue.requestOptionValue === IChangeRequestOptions.RESUBMIT ||
        this.actionValue.requestOptionValue === IChangeRequestOptions.CREATE
      ) {
        // If action is resubmit, patch call to update exiting CR; If actions is create, post call to create a new CR
        if (this.actionValue.requestEntityType === IChangeRequestEntityType.CLE) {
          this.sendCLEChangeRequest(apiAction, query);
        } else {
          // account level
          this.sendAccountChangeRequest(apiAction, query);
        }
      } else {
        this.store.dispatch(ChangeRequestActions.sendResponseOfChangeRequest(query));
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  public cancel(): void {
    this.bsModalRef.hide();
    this.store.dispatch(ChangeRequestActions.resetChangeRequestModal());
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getTitle(): void {
    return this.getOptionMessage().title.replace(
      '{actionValue}',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `<span class="change-request-highlight">${this.actionValue.requestOptionValue}</span>`
    );
  }

  public getMessage(): void {
    const needsNotifyRequesterAction = [
      IChangeRequestOptions.SEND_BACK,
      IChangeRequestOptions.APPROVE,
      IChangeRequestOptions.REJECT
    ];

    const emailReceiver = needsNotifyRequesterAction.includes(this.actionValue.requestOptionValue)
      ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `requester:${this.requesterToReceiveEmailNotification}`
      : 'credit team';
    return this.getOptionMessage().message.replace(
      '{emailReceiver}',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      emailReceiver
    );
  }

  public getOptionMessage(): any {
    if (this.actionValue.requestOptionValue === IChangeRequestOptions.RESUBMIT) {
      this.actionValue.requestEntityType === IChangeRequestEntityType.CLE
        ? (this.options = {
            title: RESBUMIT_CLE_CHANGE.title,
            message: RESBUMIT_CLE_CHANGE.message,
            confirmLabel: RESBUMIT_CLE_CHANGE.confirmLabel,
            cancelLabel: RESBUMIT_CLE_CHANGE.cancelLabel,
            allowCancel: true,
            showConfirmCheckbox: true,
            closeIcon: true
          })
        : (this.options = {
            title: RESBUMIT_ACCOUNT_CHANGE.title,
            message: RESBUMIT_ACCOUNT_CHANGE.message,
            confirmLabel: RESBUMIT_ACCOUNT_CHANGE.confirmLabel,
            cancelLabel: RESBUMIT_CLE_CHANGE.cancelLabel,
            allowCancel: true,
            showConfirmCheckbox: true,
            closeIcon: true
          });
      return this.options;
    } else if (this.actionValue.requestOptionValue === IChangeRequestOptions.CREATE) {
      this.actionValue.requestEntityType === IChangeRequestEntityType.CLE
        ? (this.options = {
            title: CREATE_NEW_CLE_REQUEST.title,
            message: CREATE_NEW_CLE_REQUEST.message,
            confirmLabel: CREATE_NEW_CLE_REQUEST.confirmLabel,
            cancelLabel: CREATE_NEW_CLE_REQUEST.cancelLabel,
            allowCancel: true,
            showConfirmCheckbox: true,
            closeIcon: true
          })
        : (this.options = {
            title: CREATE_NEW_ACCOUNT_REQUEST.title,
            message: CREATE_NEW_ACCOUNT_REQUEST.message,
            confirmLabel: CREATE_NEW_ACCOUNT_REQUEST.confirmLabel,
            cancelLabel: CREATE_NEW_ACCOUNT_REQUEST.cancelLabel,
            allowCancel: true,
            showConfirmCheckbox: true,
            closeIcon: true
          });
      return this.options;
    } else {
      return this.options;
    }
  }

  public sendCLEChangeRequest(apiAction: IChangeRequestActions, query: any): void {
    this.actionValue.reSubmitPayload.changeRequest =
      apiAction === IChangeRequestActions.RESUBMIT
        ? {
            ...query,
            payload: this.actionValue.reSubmitPayload.changeRequest
          }
        : {
            ...this.actionValue.reSubmitPayload.changeRequest,
            comment: query.comment,
            payload: this.actionValue.reSubmitPayload.changeRequest.payload
          };
    this.store.dispatch(CLEActions.updateCLE(this.actionValue.reSubmitPayload));
  }

  public sendAccountChangeRequest(apiAction: IChangeRequestActions, query: any): void {
    this.actionValue.reSubmitPayload.changeRequest =
      apiAction === IChangeRequestActions.RESUBMIT
        ? {
            ...query,
            ...(!this.form.value?.movedTo?.legalEntityCode && {
              payload: this.actionValue.reSubmitPayload.changeRequest
            }),
            ...(this.form.value?.movedTo?.legalEntityCode && {
              movedTo: this.form.value.movedTo.codeAndName.split(',')[0]
            })
          }
        : {
            ...this.actionValue.reSubmitPayload.changeRequest,
            comment: query.comment,
            ...(this.actionValue.reSubmitPayload.changeRequest.payload && {
              payload: this.actionValue.reSubmitPayload.changeRequest.payload
            }),
            ...(this.actionValue.reSubmitPayload.changeRequest.requestType === IChangeRequestType.MOVE && {
              movedTo: this.form.value.movedTo.codeAndName.split(',')[0]
            })
          };
    this.actionValue.AccountAction === AccountActionRelatedChangeRequest.UPDATE_ACCOUNT_INFO_AND_CONTACT
      ? this.store.dispatch(
          AccountActions.updateAccountAddressAndContact(
            this.actionValue.reSubmitPayload,
            this.actionValue.accountNeedAddOBFsMessage
          )
        )
      : this.actionValue.AccountAction === AccountActionRelatedChangeRequest.UPDATE_ACCOUNT_DETAILS
      ? this.store.dispatch(
          AccountActions.updateAccountDetails(
            this.actionValue.reSubmitPayload,
            false,
            this.actionValue.accountNeedAddOBFsMessage
          )
        )
      : apiAction === IChangeRequestActions.CREATE
      ? this.store.dispatch(ChangeRequestActions.createChangeRequest(this.actionValue?.reSubmitPayload?.changeRequest))
      : this.store.dispatch(
          ChangeRequestActions.sendResponseOfChangeRequest(this.actionValue?.reSubmitPayload?.changeRequest)
        );
  }

  public search(search: string): void {
    if (this.canARAccountMoveToNewCLE(this.accountDetail)) {
      const query: ICLEQuery = {
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
        legalEntityCode: search,
        legalName: search,
        multiSearch: true,
        active: true
      };
      this.store.dispatch(CLEActions.load(query));
    } else {
      const query = {
        accountGroup: this.parentAccountGroup,
        multiSearch: true,
        name: search,
        accountNumber: search
      };

      this.store.dispatch(AccountActions.loadParentAccounts(query));
    }
  }

  private subscribeToCleList(): void {
    this.subscription.add(
      this.store.select(getCLEPage).subscribe((legalEntityList) => {
        const cleList = legalEntityList.items;
        if (cleList) {
          this.allowMovedToList = {
            loaded: true,
            items:
              cleList.length > 0
                ? cleList.map((value) => {
                    return {
                      ...value,
                      codeAndName: `${value.legalEntityCode}, ${value.details.legalName}`
                    };
                  })
                : []
          };
        }
      })
    );
  }

  private invalidPostingAccountSelection(
    currentParent: string,
    movedTo: string,
    currentAccount: IAccountDetails
  ): ValidatorFn {
    return (control: UntypedFormControl) => {
      let validation = null;
      this.showUnmatahedSalesOrgs = false;
      if (control.value) {
        const newARCodeList = control.value.salesOrgs ? control.value?.salesOrgs.map((x) => x.code) : null;
        const currentCodeList = currentAccount.salesOrgs ? currentAccount.salesOrgs.map((x) => x.code) : null;
        const cleCode = control.value.codeAndName.split(',')[0];
        if (cleCode === currentParent) {
          validation = {
            sameFolderSelected: currentParent
          };
        } else if (cleCode === movedTo) {
          validation = {
            pattern: movedTo
          };
        }
        // when user move Billing account to a new AR needs to check if new AR account contains valid sales orgs combination
        else if (
          !this.canARAccountMoveToNewCLE(this.accountDetail) &&
          (!newARCodeList || !currentCodeList || !currentCodeList.every((v) => newARCodeList.includes(v)))
        ) {
          this.showUnmatahedSalesOrgs = !currentCodeList.every((v) => newARCodeList.includes(v));
          this.requiredNewSalesCode = difference(currentCodeList, newARCodeList);
          validation = {
            pattern: currentParent
          };
        }
      }
      return validation;
    };
  }

  public getTooltipMessage(): string {
    const successMessage = getStaticContent(
      'change_request.details.metadata.invalid_combination',
      staticContent
    ).replace('{salesCode}', this.requiredNewSalesCode);

    return this.showUnmatahedSalesOrgs ? successMessage : null;
  }

  public loadAccountDetailForAccountMove(): void {
    this.subscription.add(
      this.store.select(getAccountDetails).subscribe((accountDetails) => {
        if (accountDetails) {
          this.accountDetail = accountDetails;
          if (this.canARAccountMoveToNewCLE(accountDetails)) {
            this.loadCLE();
          } else if (this.canBPAccountMoveToARAccountUnderDifCLE(this.accountDetail)) {
            let query: any = {};
            if (
              accountDetails.accountGroup === AccountGroup.INM6 ||
              accountDetails.accountGroup === AccountGroup.IM17
            ) {
              this.parentAccountGroup.push(AccountGroup.INM7);
              this.parentAccountGroup.push(AccountGroup.IM15);
              this.parentAccountGroup.push(AccountGroup.IM16);
              query = {
                ...query,
                accountGroup: this.parentAccountGroup,
                multiSearch: true
              };
            } else if (accountDetails.accountGroup === AccountGroup.INM1 && !accountDetails.post) {
              this.parentAccountGroup.push(AccountGroup.INM1);
              query = {
                ...query,
                accountGroup: this.parentAccountGroup
              };
            }
            this.loadAR(query);
          }
        }
      })
    );
  }

  public canARAccountMoveToNewCLE(accountDetails: IAccountDetails): boolean {
    return (
      accountDetails &&
      accountDetails.post &&
      (accountDetails.accountGroup === AccountGroup.INMA ||
        accountDetails.accountGroup === AccountGroup.INM7 ||
        accountDetails.accountGroup === AccountGroup.IM15 ||
        accountDetails.accountGroup === AccountGroup.IM16 ||
        accountDetails.accountGroup === AccountGroup.INM1 ||
        accountDetails.accountGroup === AccountGroup.INM2 ||
        accountDetails.accountGroup === AccountGroup.INDE)
    );
  }

  public canBPAccountMoveToARAccountUnderDifCLE(accountDetails: IAccountDetails): boolean {
    return (
      !accountDetails.post &&
      (accountDetails.accountGroup === AccountGroup.INM6 ||
        accountDetails.accountGroup === AccountGroup.IM17 ||
        accountDetails.accountGroup === AccountGroup.INM1)
    );
  }

  public loadCLE(): void {
    const query = {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
      active: true
    };
    this.store.dispatch(CLEActions.load(query));

    this.subscribeToCleList();
  }

  public getChangeRequestList(): void {
    this.subscription.add(
      this.store.select(getChangeRequestList).subscribe((changeRequestList) => {
        if (changeRequestList) {
          this.movedTo = changeRequestList.items[0]?.movedTo;

          if (this.showMovedTo) {
            // AR level, current Parent is CLE; BP level, current parent is AR
            const currentParent = this.canARAccountMoveToNewCLE(this.accountDetail)
              ? this.accountDetail?.legalEntityCode
              : this.accountDetail?.salesOrgs[0]?.postingAccount;
            this.movedToCtrl.setValidators([
              Validators.required,
              this.invalidPostingAccountSelection(currentParent, this.movedTo, this.accountDetail)
            ]);
          }
        }
      })
    );
  }

  public loadAR(query: any): void {
    this.store.dispatch(AccountActions.loadParentAccounts(query));
    this.subscribeToAccountList();
  }

  private subscribeToAccountList(): void {
    this.subscription.add(
      this.store.select(getParentAccountsList).subscribe((parentAccountsList) => {
        const parentList = parentAccountsList.items;
        if (parentList && parentList.length > 0) {
          this.allowMovedToList = {
            loaded: true,
            items: parentList.map((value) => {
              return {
                ...value,
                codeAndName: `${value.accountNumber}, ${value.name}`
              };
            })
          };
        }
      })
    );
  }
}
